import $ from './jquery'
import select2 from 'select2'
import 'jquery-datetimepicker'

select2()

function validateEmail (email) {
  // eslint-disable-next-line max-len
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

function initSelects () {
  $.datetimepicker.setLocale('fr')
  $('.datetimepicker').datetimepicker({
    lang: 'fr'
  })

  $('.select2').select2({
    theme: 'bootstrap4',
    width: 'style'
  })

  $('.tokenized').select2({
    theme: 'bootstrap4',
    width: 'style',
    tags: true,
    selectOnClose: true,
    tokenSeparators: [','],
    templateResult: (data) => {
      // We only really care if there is an element to pull classes from
      if (!data.element) {
        return data.text
      }

      const $element = $(data.element)

      const $wrapper = $('<span></span>')
      $wrapper.addClass($element[0].className)
      $wrapper.css('color', $element[0].style.color)
      $wrapper.text(data.text)

      return $wrapper
    }
  })

  $('.tokenized-emails').select2({
    theme: 'bootstrap4',
    width: 'style',
    tags: true,
    tokenSeparators: [',', ' '],
    minimumResultsForSearch: 1,
    selectOnClose: true,
    createTag: (term) => {
      const value = term.term
      if (validateEmail(value)) {
        return {
          id: value,
          text: value
        }
      }
      return null
    }
  }).on('select2:open', () => {
    $('.select2-container--open .select2-dropdown--below').css('display', 'none')
  })
}

$(document).on('turbolinks:before-cache', () => {
  $('.select2-hidden-accessible').select2('destroy')
})

$(document).on('turbolinks:load', () => {
  initSelects()
})
