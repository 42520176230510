import Dropzone from 'dropzone'
import { DirectUpload } from '@rails/activestorage'
import axios from 'axios'

Dropzone.autoDiscover = false

export default class DirecDropzone {
  constructor (form) {
    this.form = form
    this.inputTarget = form.querySelector('input[type=file]')
    this.hideFileInput()

    const image = form.querySelector('img')
    const mockFile = { name: image.dataset.filename, size: image.dataset.fileSize, accepted: true }
    image.style.display = 'none'

    this.dropzone = new Dropzone(form, {
      url: form.action,
      autoQueue: false,
      maxFiles: 1,
      maxfilesexceeded (file) {
        this.removeAllFiles()
        this.addFile(file)
      }
    })

    // Add current file
    this.dropzone.emit('addedfile', mockFile)
    this.dropzone.emit('thumbnail', mockFile, image.src)
    this.dropzone.emit('complete', mockFile)
    this.dropzone.files.push(mockFile)

    this.dropzone.on('addedfile', (file) => {
      // event.preventDefault();
      this.createDirectUpload(file)
    })
  }

  hideFileInput () {
    this.inputTarget.disabled = true
    this.inputTarget.style.display = 'none'
  }

  createDirectUpload (file) {
    // your form needs the file_field direct_upload: true, which
    //  provides data-direct-upload-url
    const url = this.inputTarget.dataset.directUploadUrl
    const { dropzone } = this
    const upload = new DirectUpload(file, url, {
      directUploadWillStoreFileWithXHR: (request) => {
        request.upload.addEventListener('progress', (event) => {
          dropzone.emit('uploadprogress', file, event.total)
        })
      }
    })

    const { form } = this
    upload.create((error, blob) => {
      if (error) {
        // Handle the error
      } else {
        // Add an appropriately-named hidden input to the form with a
        //  value of blob.signed_id so that the blob ids will be
        //  transmitted in the normal upload flow
        this.dropzone.emit('success', file)
        this.dropzone.emit('complete', file)
        this.inputTarget.setAttribute('value', blob.signed_id)

        const formData = new FormData(form)
        formData.append(this.inputTarget.name, blob.signed_id)
        axios.post(form.action, formData)
          .then((response) => {
            // handle success
            console.log(response)
          })
          .catch((response) => {
            // handle error
            console.log(response)
          })
      }
    })
  }
}
