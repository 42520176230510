import $ from './jquery'
import Rails from 'rails-ujs'
import Turbolinks from 'turbolinks'
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import 'trix'
import '@rails/actiontext'
import '@fortawesome/fontawesome-free/js/all'
import 'bootstrap'

import './pagy'
import DirectDropzone from './direct-dropzone'
import './initSelects'

Rails.start()
Turbolinks.start()

library.add(fas)

document.addEventListener('turbolinks:load', () => {
  dom.i2svg()
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  $('[data-toggle="forward"]').on('click', event => {
    event.preventDefault()
    $(event.target.dataset.target).click()
  })

  const copyButtons = document.querySelectorAll('.btn-copy')
  copyButtons.forEach((button) => {
    button.addEventListener('click', () => {
      const copyText = document.querySelector(button.dataset.clipboardTarget)

      /* Select the text field */
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand('copy')

      /* Alert the copied text */
      $(button).tooltip({
        title: button.dataset.title,
        trigger: 'manual'
      })
      $(button).tooltip('show')
      setTimeout(() => {
        $(button).tooltip('hide')
      }, 2000)
    })
  })

  const forms = document.querySelectorAll('.direct-dropzone')
  forms.forEach(form => new DirectDropzone(form))

  $('#question_is_urgent_confirmation_confirm').click(() => {
    $('#question_is_urgent_confirmation').modal('hide')
    $('#question_is_urgent').prop('checked', true)
  })

  $('#question_is_urgent').click(() => {
    if (!$('#question_is_urgent').prop('checked')) {
      return true
    }
    $('#question_is_urgent_confirmation').modal({ show: true })
    return false
  })

  $('.pop')
    .on('mouseenter', () => {
      $('.pop').popover('show')
      $('.popover').on('mouseleave', () => {
        $('.pop').popover('hide')
      })
    }).on('mouseleave', () => {
      setTimeout(() => {
        if (!$('.popover:hover').length) {
          $('.pop').popover('hide')
        }
      }, 300)
    })
})
